<template>
    <div class="d-flex flex-column justify-center align-center fill-height py-8 overflow-hidden">
        <v-card v-if="!allInfoLoaded">
            <v-skeleton-loader
                type="card, list-item-two-line, divider, list-item-avatar-three-line, divider, list-item-avatar-three-line, divider, actions"
                width="350"></v-skeleton-loader>
        </v-card>
        <div v-else-if="!isRegisteringOrAccepting" class="cloud-wrapper">
            <v-card width="350">
                <v-card-title class="justify-center align-center nuvolos-header">
                    <v-img max-width="200" max-height="22" class="py-5" src="@/assets/Nuvolos-transparent.svg" />
                </v-card-title>

                <template v-if="validInvitation && recommendedAction !== 'None'">
                    <h2 class="secondary--text text-center text-h6 mt-5" v-if="isTrial">Create a Nuvolos account</h2>
                    <template v-if="!isTrial">
                        <h2 class="secondary--text text-center text-h6 mt-5">Join Nuvolos</h2>

                        <v-card-text>You have received invitation(s) to Nuvolos. Please review the grants and continue if you wish to accept.</v-card-text>

                        <v-subheader>REVIEW GRANTS</v-subheader>
                        <v-divider></v-divider>
                        <template v-for="(r, index) in roles">
                            <v-list-item :key="`grants-${index}`">
                                <v-list-item-content>
                                    <v-list-item-title class="mb-2">Invitation {{ index + 1 }}</v-list-item-title>
                                    <v-list-item-subtitle v-for="(value, name) in user_pretty(r)" :key="name" class="text-truncate">
                                        {{ name }}:
                                        <span class="font-weight-bold secondary--text">{{ value }}</span>
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-divider :key="`divider-${index}`"></v-divider>
                        </template>
                    </template>

                    <v-card-text>
                        <template v-if="emailIsRegistered !== null">
                            <template v-if="!$auth.isAuthenticated && !emailIsRegistered">
                                <institution-login v-if="!isTrial" class="mt-3">
                                    <v-expansion-panels hover class="mt-3 flat-expansion-panel">
                                        <v-expansion-panel class="elevation-0">
                                            <v-expansion-panel-header class="rounded">Create new account</v-expansion-panel-header>
                                            <v-expansion-panel-content class="pt-4">
                                                <div v-if="recommendedAction === recommendedActions.SIGN_UP_NEW_USER">
                                                    <the-email-signup-option :componentSpecificProps="{ userEmail: email, isTrial }" />
                                                </div>
                                                <div v-if="recommendedAction === recommendedActions.SIGN_UP_WITH_EXISTING_MIDPOINT_ACCOUNT">
                                                    <the-upgrade-to-nuvolos-option :componentSpecificProps="{ userEmail: email }" />
                                                </div>
                                            </v-expansion-panel-content>
                                        </v-expansion-panel>
                                    </v-expansion-panels>
                                </institution-login>
                                <div v-else>
                                    Please provide your details below to set up your trial account:
                                    <the-email-signup-option :componentSpecificProps="{ userEmail: email }" class="mt-4" />
                                </div>
                            </template>

                            <template v-else-if="$auth.isAuthenticated">
                                <p>
                                    The invitation seems to correspond to a different e-mail. Please log out from the current account and then open again the
                                    invitation.
                                </p>
                                <v-card-actions>
                                    <v-btn color="primary" elevation="0" block @click="$auth.logout">log out</v-btn>
                                </v-card-actions>
                            </template>

                            <template v-else>
                                <p>
                                    The e-mail for this invitation is already registered. After clicking on the button below, use the following e-mail to sign
                                    in:
                                </p>
                                <p class="text-center">
                                    <b>{{ email }}</b>
                                </p>
                                <v-card-actions>
                                    <v-btn color="primary" elevation="0" block @click="loginAndReturn">Next</v-btn>
                                </v-card-actions>
                            </template>
                        </template>

                        <template v-else>
                            <p>Checking if e-mail is registered already...</p>
                        </template>
                    </v-card-text>
                </template>

                <v-card-text v-else-if="!loadingInvitation" class="pt-5">
                    <v-alert dense text type="error">We are sorry but this invitation is no longer valid.</v-alert>
                    <v-card-actions>
                        <v-btn elevation="0" block color="primary" :to="{ name: 'login' }">Sign in to Nuvolos</v-btn>
                    </v-card-actions>
                </v-card-text>
            </v-card>
            <img src="@/assets/cloud-1.svg" class="cloud cloud-1" />
            <img src="@/assets/cloud-3.svg" class="cloud cloud-3" />
        </div>
    </div>
</template>

<script>
import { defaultAccountType } from '@/utils'
import { mapState } from 'vuex'
import store from 'store'
const TheUpgradeToNuvolosOption = () => import('../components/TheUpgradeToNuvolosOption')
const TheEmailSignupOption = () => import('../components/TheEmailSignupOption')
const InstitutionLogin = () => import('@/modules/invitations/components/InstitutionLogin.vue')

export default {
    name: 'invitation-review',
    components: {
        TheUpgradeToNuvolosOption,
        TheEmailSignupOption,
        InstitutionLogin
    },
    data() {
        return {
            validInvitation: null,
            roles: '',
            email: '',
            recommendedAction: '',
            emailType: null,
            invitationStatus: '',
            invitationOptionsPanel: 0,
            recommendedActions: {
                LOGIN_WITH_SWITCH: 'login/switch',
                LOGIN_WITH_NUVOLOS_ACCOUNT: 'login/password',
                LOGIN_WITH_GOOGLE: 'login/google',
                SIGN_UP_WITH_EXISTING_MIDPOINT_ACCOUNT: 'register/midpoint',
                SIGN_UP_NEW_USER: 'register'
            },
            statusOptions: {
                REGISTERING_ACCOUNT: 'REGISTERING',
                ACCEPTING_INVITATION: 'ACCEPTING'
            },
            emailIsRegistered: null,
            loadingInvitation: true,
            isTrial: false,
            igid: null
        }
    },
    mounted() {
        this.$axios
            .get(`/invitations/describe/${this.$route.params.invid}`)
            .then(async response => {
                this.igid = response.data.igid
                this.roles = response.data.roles
                this.validInvitation = response.data.valid === 'True'
                this.email = response.data.requestee_email
                this.isTrial = response.data.trid !== undefined && response.data.trid !== null
                const r = await this.$axios.post('/users/login_methods', { email: this.email })
                if (r.data && r.data.methods && r.data.methods.length) {
                    this.emailIsRegistered = true
                } else {
                    this.emailIsRegistered = false
                }
                this.emailType = this.getDefaultAccountType(response.data.requestee_email)
                this.recommendedAction = response.data.recommended_action
                this.invitationStatus = response.data.status_name
                if (response.data.status_name === this.statusOptions.ACCEPTING_INVITATION) {
                    this.$store.dispatch('showGlobalDialog', {
                        dialogTitle: 'Accepting invitation',
                        dialogText: 'This invitation has already been accepted, please continue to wait and reload the page after 1-2 minutes',
                        dialogAction: ['reload']
                    })
                } else if (response.data.status_name === this.statusOptions.REGISTERING_ACCOUNT) {
                    this.$store.dispatch('showGlobalDialog', {
                        dialogTitle: 'Registering account',
                        dialogText: 'Registration for this invitation has already been started, please continue to wait and reload the page after 1-2 minutes.',
                        dialogAction: ['reload']
                    })
                }
            })
            .catch(error => {
                console.log(error)
                if (error.response && error.response.status >= 500) {
                    this.$store.dispatch('showGlobalDialog', {
                        dialogTitle: 'Temporary issue',
                        dialogText: 'Something went wrong, please try to refresh this page in a few minutes.',
                        dialogAction: ['reload']
                    })
                }
                this.validInvitation = false
            })
            .finally(() => {
                this.loadingInvitation = false
            })
    },
    computed: {
        ...mapState(['userInfo', 'fetchingUserInfo']),
        nuvolosEmail() {
            if (this.userInfo && this.userInfo.email) {
                return this.userInfo.email
            } else {
                return ''
            }
        },
        isRegisteringOrAccepting() {
            if (
                (this.invitationStatus && this.invitationStatus === this.statusOptions.ACCEPTING_INVITATION) ||
                this.invitationStatus === this.statusOptions.REGISTERING_ACCOUNT
            ) {
                return true
            }
            return false
        },
        allInfoLoaded() {
            if (!this.loadingInvitation && !this.fetchingUserInfo && !this.$auth.loading) {
                return true
            }
            return false
        }
    },
    methods: {
        getDefaultAccountType(email) {
            return defaultAccountType(email)
        },
        user_pretty(r) {
            const prettyInvite = {}
            if (r.Name) {
                prettyInvite.Role = r.role_type === 'ResourcePoolRoleType' ? `RESOURCE POOL ${r.Name}` : r.Name
            }
            if (r.Instance) {
                prettyInvite.Instance = r.Instance
            }
            if (r.Space) {
                prettyInvite.Space = r.Space
            }
            if (r.Organization) {
                prettyInvite.Organization = r.Organization
            }
            return prettyInvite
        },
        loginAndReturn() {
            store.set('nvLastEmail', this.email)
            this.$router.push({ name: 'login', query: { targetUrl: location.pathname } })
        }
    },
    watch: {
        allInfoLoaded() {
            if (this.emailIsRegistered && !this.$auth.isAuthenticated && this.validInvitation) {
                this.loginAndReturn()
                return
            }

            if (!this.igid && this.emailIsRegistered !== null) {
                if (this.nuvolosEmail.toLowerCase() === this.email.toLowerCase() || (this.$auth.isAuthenticated && !this.emailIsRegistered)) {
                    this.$router.push({ name: 'invitation-check-user', params: { invid: this.$route.params.invid, type: this.$route.params.type } })
                }
            }

            if (this.igid && this.emailIsRegistered !== null) {
                if (!this.$auth.isAuthenticated && !this.emailIsRegistered) {
                    // Create new account
                } else if (this.$auth.isAuthenticated && !this.emailIsRegistered) {
                    // Logged in and can continue to accept inv
                    this.$router.push({ name: 'invitation-check-user', params: { invid: this.$route.params.invid, type: this.$route.params.type } })
                } else if (this.nuvolosEmail.toLowerCase() === this.email.toLowerCase()) {
                    this.$router.push({ name: 'invitation-accept', params: { invid: this.$route.params.invid } })
                } else if (this.$auth.isAuthenticated) {
                    // Inv is for diff account, logout
                } else {
                    // The e-mail for this invitation is already registered
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/sass/signup.scss';
</style>
